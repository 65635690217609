import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spelling from './Spelling';

function HeroSection() {
  return (
    <div className="hero-section">
    <Container>
      <Row>
        <Col><h1 className='gradient-text'>A Next-Generation AI Support Assistant</h1></Col>
      </Row>
    </Container>
    <Spelling/>
    </div>
  );
}

export default HeroSection;